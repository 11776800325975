<template>
    <v-row justify="center">
        <v-col xl="9">
            <v-card flat>
                <v-card-title>
                    <div style="width:100%" class="d-flex justify-space-between align-center">
                        <span class="font-weight-bold primary--text">Dashboard</span>
                        <div class="d-flex align-center">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="d-flex align-center"
                                        text
                                        color="primary"
                                        v-on="on"
                                        href="https://docs.nuvolos.cloud/quickstart/getting-started-with-nuvolos"
                                        target="_blank"
                                    >
                                        <v-icon small class="mr-1">menu_book</v-icon>
                                        <span class="overline font-weight-bold">getting started</span>
                                    </v-btn>
                                </template>
                                <span>Read the documentation on how to get started with Nuvolos</span>
                            </v-tooltip>
                            <v-menu :close-on-content-click="true" offset-y bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" color="primary" icon dark class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense nav>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <LeaveDialog levelLeavingTitle="leave organization" hierarchicalLevel="organization" />
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </div>
                </v-card-title>
                <HomeDashboardCard icon="desktop_windows" subTitle="Recent Apps">
                    <div
                        class="mx-n6"
                        data-intro="You can click on the application tiles to launch an app. Hovering on the power button reveals further details."
                        data-step="2"
                    >
                        <v-row class="mt-4">
                            <v-col xs="12" sm="12" md="4" v-for="item in recentApps" :key="item.aid">
                                <div class="px-6">
                                    <v-card flat outlined>
                                        <v-card-text class="pa-0">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-list-item
                                                        two-line
                                                        v-on="on"
                                                        :to="{
                                                            name: 'app-open',
                                                            params: {
                                                                oid: item.org_id,
                                                                sid: item.space_id,
                                                                iid: item.instance_id,
                                                                snid: item.snid,
                                                                aid: item.aid
                                                            }
                                                        }"
                                                    >
                                                        <v-list-item-avatar>
                                                            <img :src="getAppLink(item.app_type)" />
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                <h4 class="mr-1 text-truncate primary--text">
                                                                    {{ item.long_id }}
                                                                </h4>
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle>
                                                                <span class="caption mr-3 font-weight-bold">{{ item.description }}</span>
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <v-menu
                                                                :close-on-content-click="true"
                                                                :open-on-hover="true"
                                                                bottom
                                                                offset-y
                                                                left
                                                                :close-delay="100"
                                                            >
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn
                                                                        v-on="on"
                                                                        icon
                                                                        :dark="
                                                                            startingDeployments.includes(parseInt(item.aid, 10)) === true ||
                                                                                runningDeployments.includes(parseInt(item.aid, 10)) === true
                                                                        "
                                                                        :style="
                                                                            startingDeployments.includes(parseInt(item.aid, 10)) === true
                                                                                ? 'background-color:#ff5722;opacity: 0.75'
                                                                                : runningDeployments.includes(parseInt(item.aid, 10)) === true
                                                                                ? 'background-color:#11cf82; opacity: 0.75'
                                                                                : ''
                                                                        "
                                                                    >
                                                                        <v-icon v-if="ApplicationInTheSameRegion(item.region)">settings_power</v-icon>
                                                                        <v-icon v-else>help_outline</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <v-card>
                                                                    <v-card-text class="pa-0">
                                                                        <v-list dense nav>
                                                                            <v-list-item-group color="primary">
                                                                                <v-list-item disabled two-line>
                                                                                    <v-list-item-content>
                                                                                        <v-list-item-title
                                                                                            ><span class="subtitle-2">{{
                                                                                                item.long_id
                                                                                            }}</span></v-list-item-title
                                                                                        >
                                                                                        <v-list-item-content>
                                                                                            <div
                                                                                                style="max-width:200px"
                                                                                                class="d-flex flex-column"
                                                                                                v-if="ApplicationInTheSameRegion(item.region) === false"
                                                                                            >
                                                                                                <span class="text-uppercase overline mb-1">
                                                                                                    unknown
                                                                                                </span>
                                                                                                <span class="caption">
                                                                                                    App in a different region, click
                                                                                                    <span class="font-weight-bold">Open Application</span> or
                                                                                                    <span class="font-weight-bold">Open Instance</span> to
                                                                                                    change the region.
                                                                                                </span>
                                                                                            </div>
                                                                                            <span
                                                                                                v-else-if="
                                                                                                    runningDeployments.includes(parseInt(item.aid, 10)) === true
                                                                                                "
                                                                                                class="overline text-uppercase"
                                                                                                >running</span
                                                                                            >
                                                                                            <span
                                                                                                v-else-if="
                                                                                                    startingDeployments.includes(parseInt(item.aid, 10)) ===
                                                                                                        true
                                                                                                "
                                                                                                class="overline text-uppercase"
                                                                                                >starting..</span
                                                                                            >
                                                                                            <span
                                                                                                v-else-if="
                                                                                                    runningDeployments.includes(parseInt(item.aid, 10)) ===
                                                                                                        false
                                                                                                "
                                                                                                class="overline text-uppercase"
                                                                                                >stopped</span
                                                                                            ></v-list-item-content
                                                                                        >
                                                                                    </v-list-item-content>
                                                                                </v-list-item>

                                                                                <v-divider></v-divider>
                                                                                <v-list-item
                                                                                    :to="{
                                                                                        name: 'app-open',
                                                                                        params: {
                                                                                            oid: item.org_id,
                                                                                            sid: item.space_id,
                                                                                            iid: item.instance_id,
                                                                                            snid: item.snid,
                                                                                            aid: item.aid
                                                                                        }
                                                                                    }"
                                                                                >
                                                                                    <v-list-item-content>
                                                                                        <v-list-item-title>
                                                                                            <div
                                                                                                class="d-flex align-center"
                                                                                                v-if="
                                                                                                    runningDeployments.includes(parseInt(item.aid, 10)) ||
                                                                                                        startingDeployments.includes(parseInt(item.aid, 10)) ===
                                                                                                            true ||
                                                                                                        ApplicationInTheSameRegion(item.region) === false
                                                                                                "
                                                                                            >
                                                                                                <v-icon small>launch</v-icon>
                                                                                                <span class="primary--text subtitle-2 ml-1"
                                                                                                    >Open application</span
                                                                                                >
                                                                                            </div>
                                                                                            <div class="d-flex align-center" v-else>
                                                                                                <v-icon small>play_arrow</v-icon>
                                                                                                <span class="primary--text subtitle-2 ml-1"
                                                                                                    >Start application</span
                                                                                                >
                                                                                            </div>
                                                                                        </v-list-item-title>
                                                                                    </v-list-item-content>
                                                                                </v-list-item>
                                                                                <v-list-item
                                                                                    @click="stopApp(item.aid)"
                                                                                    v-if="
                                                                                        runningDeployments.includes(parseInt(item.aid, 10)) === true ||
                                                                                            startingDeployments.includes(parseInt(item.aid, 10)) === true
                                                                                    "
                                                                                >
                                                                                    <v-list-item-content>
                                                                                        <v-list-item-title>
                                                                                            <div class="d-flex align-center">
                                                                                                <v-icon small>stop</v-icon>
                                                                                                <span class="primary--text subtitle-2 ml-1">Stop</span>
                                                                                            </div>
                                                                                        </v-list-item-title>
                                                                                    </v-list-item-content>
                                                                                </v-list-item>
                                                                                <v-divider></v-divider>
                                                                                <v-list-item
                                                                                    :to="{
                                                                                        name: 'snapshot-overview',
                                                                                        params: {
                                                                                            oid: item.org_id,
                                                                                            sid: item.space_id,
                                                                                            iid: item.instance_id,
                                                                                            snid: item.snid
                                                                                        }
                                                                                    }"
                                                                                >
                                                                                    <v-list-item-content>
                                                                                        <v-list-item-title>
                                                                                            <div class="d-flex align-center">
                                                                                                <v-icon small>group</v-icon>
                                                                                                <span class="primary--text subtitle-2 ml-1">Open instance</span>
                                                                                            </div>
                                                                                        </v-list-item-title>
                                                                                    </v-list-item-content>
                                                                                </v-list-item>
                                                                            </v-list-item-group>
                                                                        </v-list>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-menu>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </template>
                                                <div class="d-flex flex-column caption">
                                                    <span>
                                                        Space:
                                                        <span class="font-weight-bold text-uppercase">{{ item.space_name }}</span>
                                                    </span>
                                                    <span>
                                                        Instance:
                                                        <span class="font-weight-bold text-uppercase">{{ item.instance_name }}</span>
                                                    </span>
                                                </div>
                                            </v-tooltip>
                                        </v-card-text>
                                    </v-card>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="recentApps.length === 0" class="mt-4">
                        <div class="caption text-center">No recent apps, add a course / project first.</div>
                    </div>
                </HomeDashboardCard>
                <v-row class="mt-10 pt-12">
                    <v-col xs="12" sm="12" md="4">
                        <HomeDashboardCard icon="school" subTitle="Recent Courses">
                            <div v-if="fetchingSpaces === false">
                                <div v-for="item in recentEducationSpaces" :key="item.sid" class="my-6">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-card v-on="on" flat outlined v-if="fetchingSpaces === false">
                                                <v-card-text class="pa-0">
                                                    <v-list-item
                                                        :to="{
                                                            name: 'snapshot-overview',
                                                            params: { oid: $route.params.oid, sid: item.sid, iid: item.iid, snid: item.snid }
                                                        }"
                                                        two-line
                                                    >
                                                        <v-list-item-avatar>
                                                            <v-icon x-large>school</v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                <h4 class="mr-2 primary--text text-truncate">{{ item.space_long_id }}</h4>
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle>
                                                                <span class="caption mr-3 font-weight-bold">{{ item.space_description }}</span>
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-card-text>
                                            </v-card>
                                        </template>
                                        <span>{{ item.space_long_id }}</span>
                                    </v-tooltip>
                                </div>
                                <div v-if="educationSpaces.length > 2" class="d-flex flex-column justify-start align-center">
                                    <router-link
                                        :to="{ name: 'home-add-class', params: { oid: $route.params.oid } }"
                                        style="text-decoration:none"
                                        v-if="isOrgManager || isOrgFaculty"
                                    >
                                        <div class="d-flex justify-center align-center">
                                            <v-icon small>add</v-icon><span class="caption primary--text">New Course</span>
                                        </div>
                                    </router-link>
                                    <router-link
                                        v-if="educationSpaces.length > 3"
                                        :to="{ name: 'home-education-spaces', params: { oid: $route.params.oid } }"
                                        style="text-decoration:none"
                                        class="mt-2"
                                    >
                                        <div class="d-flex justify-center align-center">
                                            <v-icon small>list</v-icon><span class="caption primary--text ml-1">All Courses</span>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                            <div v-else>
                                <div
                                    v-for="item in [
                                        { id: 'c1', opacity: 1 },
                                        { id: 'c2', opacity: 0.75 },
                                        { id: 'c3', opacity: 0.5 }
                                    ]"
                                    :key="item.id"
                                    class="my-6"
                                >
                                    <div :style="{ opacity: item.opacity }">
                                        <v-skeleton-loader type="list-item-avatar-two-line" class="mx-auto" />
                                    </div>
                                </div>
                            </div>
                            <div class="mt-6 pa-0 py-n2" v-if="recentEducationSpaces.length < 3 && (isOrgManager || isOrgFaculty) && !fetchingSpaces">
                                <v-list-item @click="createSpaceAndApp('class')" class="py-2">
                                    <v-list-item-avatar>
                                        <v-icon x-large>add</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4 class="mr-2 primary--text">New Course</h4>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                            <div
                                v-if="recentEducationSpaces.length === 0 && isOrgManager === false && isOrgFaculty === false && fetchingSpaces === false"
                                class="mt-4"
                            >
                                <div class="caption text-center">No courses to display.</div>
                            </div>
                        </HomeDashboardCard>
                    </v-col>
                    <v-col xs="12" sm="12" md="4">
                        <HomeDashboardCard icon="mdi-beaker" subTitle="Recent Projects">
                            <div v-if="fetchingSpaces === false">
                                <div v-for="item in recentResearchSpaces" :key="item.sid" class="my-6">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-card v-on="on" flat outlined>
                                                <v-card-text class="pa-0">
                                                    <v-list-item
                                                        :to="{
                                                            name: 'snapshot-overview',
                                                            params: { oid: $route.params.oid, sid: item.sid, iid: item.iid, snid: item.snid }
                                                        }"
                                                        two-line
                                                    >
                                                        <v-list-item-avatar>
                                                            <v-icon x-large>mdi-beaker</v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                <h4 class="mr-2 primary--text text-truncate">{{ item.space_long_id }}</h4>
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle>
                                                                <span class="caption mr-3 font-weight-bold">{{ item.space_description }}</span>
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-card-text>
                                            </v-card>
                                        </template>
                                        <span>{{ item.space_long_id }}</span>
                                    </v-tooltip>
                                </div>
                                <div v-if="researchSpaces.length > 2" class="d-flex flex-column align-center justify-start">
                                    <router-link
                                        :to="{ name: 'home-add-research-project', params: { oid: $route.params.oid } }"
                                        style="text-decoration:none"
                                        v-if="isOrgManager || isOrgFaculty"
                                    >
                                        <div class="d-flex justify-center align-center">
                                            <v-icon small>add</v-icon><span class="caption primary--text">New Research Project</span>
                                        </div>
                                    </router-link>
                                    <router-link
                                        v-if="researchSpaces.length > 3"
                                        :to="{ name: 'home-research-spaces', params: { oid: $route.params.oid } }"
                                        style="text-decoration: none;"
                                        class="mt-2"
                                    >
                                        <div class="d-flex align-center">
                                            <v-icon small>list</v-icon><span class="caption primary--text ml-1">All Research Projects</span>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                            <div v-else>
                                <div
                                    v-for="item in [
                                        { id: 'r1', opacity: 1 },
                                        { id: 'r2', opacity: 0.75 },
                                        { id: 'r3', opacity: 0.5 }
                                    ]"
                                    :key="item.id"
                                    class="my-6"
                                >
                                    <div :style="{ opacity: item.opacity }">
                                        <v-skeleton-loader type="list-item-avatar-two-line" class="mx-auto" />
                                    </div>
                                </div>
                            </div>

                            <div class="mt-6 pa-0 py-n2" v-if="recentResearchSpaces.length < 3 && (isOrgManager || isOrgFaculty) && !fetchingSpaces">
                                <v-list-item @click="createSpaceAndApp('research')" class="py-2">
                                    <v-list-item-avatar>
                                        <v-icon x-large>add</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4 class="mr-2 primary--text">New Research Project</h4>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                            <div
                                v-if="recentResearchSpaces.length === 0 && isOrgManager === false && isOrgFaculty === false && fetchingSpaces === false"
                                class="mt-4"
                            >
                                <div class="caption text-center">No research spaces to display.</div>
                            </div>
                        </HomeDashboardCard>
                    </v-col>
                    <v-col xs="12" sm="12" md="4">
                        <HomeDashboardCard icon="mdi-database" subTitle="Recent Datasets">
                            <div v-if="fetchingSpaces === false">
                                <div v-for="item in recentDatasetSpaces" :key="item.sid" class="my-6">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-card v-on="on" flat outlined>
                                                <v-card-text class="pa-0">
                                                    <v-list-item
                                                        :to="{
                                                            name: 'snapshot-overview',
                                                            params: { oid: $route.params.oid, sid: item.sid, iid: item.iid, snid: item.snid }
                                                        }"
                                                        two-line
                                                    >
                                                        <v-list-item-avatar>
                                                            <v-icon x-large>mdi-database</v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                <h4 class="mr-2 primary--text text-truncate">{{ item.space_long_id }}</h4>
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle>
                                                                <span class="caption mr-3 font-weight-bold">{{ item.space_description }}</span>
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-card-text>
                                            </v-card>
                                        </template>
                                        <span>{{ item.space_long_id }}</span>
                                    </v-tooltip>
                                </div>
                                <div v-if="datasetSpaces.length > 2" class="d-flex flex-column align-center justify-start">
                                    <router-link
                                        :to="{ name: 'home-add-dataset', params: { oid: $route.params.oid } }"
                                        style="text-decoration:none"
                                        v-if="isOrgManager || isOrgFaculty"
                                    >
                                        <div class="d-flex align-center"><v-icon small>add</v-icon><span class="caption primary--text">New Dataset</span></div>
                                    </router-link>
                                    <router-link
                                        v-if="datasetSpaces.length > 3"
                                        :to="{ name: 'home-dataset-spaces', params: { oid: $route.params.oid } }"
                                        style="text-decoration:none"
                                        class="mt-2"
                                    >
                                        <div class="d-flex align-center">
                                            <v-icon small>list</v-icon><span class="caption primary--text ml-1">All Datasets</span>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                            <div v-else>
                                <div
                                    v-for="item in [
                                        { id: 'd1', opacity: 1 },
                                        { id: 'd2', opacity: 0.75 },
                                        { id: 'd3', opacity: 0.5 }
                                    ]"
                                    :key="item.id"
                                    class="my-6"
                                >
                                    <div :style="{ opacity: item.opacity }">
                                        <v-skeleton-loader type="list-item-avatar-two-line" class="mx-auto" />
                                    </div>
                                </div>
                            </div>
                            <div class="mt-6 pa-0 py-n2" v-if="recentDatasetSpaces.length < 3 && isOrgManager && !fetchingSpaces">
                                <v-list-item @click="createSpaceAndApp('dataset')" class="py-2">
                                    <v-list-item-avatar>
                                        <v-icon x-large>add</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <h4 class="mr-2 primary--text">New Dataset</h4>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>
                            <div v-if="recentDatasetSpaces.length === 0 && !isOrgManager && fetchingSpaces === false" class="mt-4">
                                <div class="caption text-center">No datasets enabled, ask your organization administrator to onboard a dataset.</div>
                            </div>
                        </HomeDashboardCard>
                    </v-col>
                </v-row>
                <TheOrgSelectorDialog />
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { truncateText } from '@/utils'
import { appTypeAndImageLink } from '@/mixins/appTypeAndImage'
import regionsMixin from '@/mixins/regions'
const HomeDashboardCard = () => import('../components/HomeDashboardCard')
const TheOrgSelectorDialog = () => import('@/components/TheOrgSelectorDialog')
const LeaveDialog = () => import('@/components/LeaveDialog')

export default {
    components: { HomeDashboardCard, TheOrgSelectorDialog, LeaveDialog },
    mixins: [appTypeAndImageLink],
    methods: {
        stopApp: function(aid) {
            this.$store.dispatch('appStore/stopApp', { aid })
        },
        truncateText: function(text, length) {
            return truncateText(text, length)
        },
        ApplicationInTheSameRegion(appRegionCode) {
            if (regionsMixin.methods.getCurrentRegionEnum() === appRegionCode) {
                return true
            } else {
                return false
            }
        },
        setIntercomBoot() {
            if (this.userInfo) {
                window.Intercom('shutdown')
                window.Intercom('boot', {
                    app_id: 'lliic4ws',
                    email: this.userInfo.email,
                    user_id: this.userInfo.uid,
                    name: this.userInfo.name || this.userInfo.given_name + ' ' + this.userInfo.family_name,
                    user_type: this.isOrgManager ? 'manager' : this.isOrgFaculty ? 'faculty' : 'member'
                })
            }
        },
        createSpaceAndApp(type) {
            this.$store.dispatch('homeStore/updateCreateSpaceAndApp', {
                operationSelectedVal: true,
                stepVal: 1,
                spaceTypeVal: type
            })
            if (type === 'class') {
                this.$router.push({
                    name: 'home-add-class',
                    params: { oid: this.$route.params.oid }
                })
            } else if (type === 'research') {
                this.$router.push({
                    name: 'home-add-research-project',
                    params: { oid: this.$route.params.oid }
                })
            } else if (type === 'dataset') {
                this.$router.push({
                    name: 'home-add-dataset',
                    params: { oid: this.$route.params.oid }
                })
            }
        }
    },
    computed: {
        ...mapState(['userOrgs', 'fetchingOrgs', 'userInfo']),
        ...mapState('appStore', ['recentApps', 'fetchingApps']),
        ...mapState('orgStore', [
            'researchSpaces',
            'educationSpaces',
            'datasetSpaces',
            'recentDatasetSpaces',
            'recentResearchSpaces',
            'recentEducationSpaces',
            'spacesWithPrimarySnapshots',
            'fetchingSpaces'
        ]),
        ...mapGetters('orgStore', ['isOrgFaculty', 'isOrgManager']),
        runningDeployments() {
            const runningApps = this.$store.getters['appStore/runningDeployments']
            return runningApps.map(app => parseInt(app.aid, 10))
        },
        startingDeployments() {
            const startingApps = this.$store.getters['appStore/startingDeployments']
            return startingApps.map(app => parseInt(app.aid, 10))
        }
    },
    mounted() {
        this.setIntercomBoot()
    }
}
</script>
