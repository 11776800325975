var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { xl: "9" } },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("v-card-title", [
                _c(
                  "div",
                  {
                    staticClass: "d-flex justify-space-between align-center",
                    staticStyle: { width: "100%" }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "font-weight-bold primary--text" },
                      [_vm._v("Dashboard")]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "d-flex align-center",
                                          attrs: {
                                            text: "",
                                            color: "primary",
                                            href:
                                              "https://docs.nuvolos.cloud/quickstart/getting-started-with-nuvolos",
                                            target: "_blank"
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-1",
                                            attrs: { small: "" }
                                          },
                                          [_vm._v("menu_book")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "overline font-weight-bold"
                                          },
                                          [_vm._v("getting started")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "Read the documentation on how to get started with Nuvolos"
                              )
                            ])
                          ]
                        ),
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "close-on-content-click": true,
                              "offset-y": "",
                              bottom: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "ml-1",
                                          attrs: {
                                            color: "primary",
                                            icon: "",
                                            dark: ""
                                          }
                                        },
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("more_vert")])],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _c(
                              "v-list",
                              { attrs: { dense: "", nav: "" } },
                              [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      [
                                        _c("LeaveDialog", {
                                          attrs: {
                                            levelLeavingTitle:
                                              "leave organization",
                                            hierarchicalLevel: "organization"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ]),
              _c(
                "HomeDashboardCard",
                { attrs: { icon: "desktop_windows", subTitle: "Recent Apps" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mx-n6",
                      attrs: {
                        "data-intro":
                          "You can click on the application tiles to launch an app. Hovering on the power button reveals further details.",
                        "data-step": "2"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mt-4" },
                        _vm._l(_vm.recentApps, function(item) {
                          return _c(
                            "v-col",
                            {
                              key: item.aid,
                              attrs: { xs: "12", sm: "12", md: "4" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "px-6" },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "", outlined: "" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "pa-0" },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-list-item",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                "two-line": "",
                                                                to: {
                                                                  name:
                                                                    "app-open",
                                                                  params: {
                                                                    oid:
                                                                      item.org_id,
                                                                    sid:
                                                                      item.space_id,
                                                                    iid:
                                                                      item.instance_id,
                                                                    snid:
                                                                      item.snid,
                                                                    aid:
                                                                      item.aid
                                                                  }
                                                                }
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-list-item-avatar",
                                                              [
                                                                _c("img", {
                                                                  attrs: {
                                                                    src: _vm.getAppLink(
                                                                      item.app_type
                                                                    )
                                                                  }
                                                                })
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  [
                                                                    _c(
                                                                      "h4",
                                                                      {
                                                                        staticClass:
                                                                          "mr-1 text-truncate primary--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              item.long_id
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-list-item-subtitle",
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "caption mr-3 font-weight-bold"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.description
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-action",
                                                              [
                                                                _c(
                                                                  "v-menu",
                                                                  {
                                                                    attrs: {
                                                                      "close-on-content-click": true,
                                                                      "open-on-hover": true,
                                                                      bottom:
                                                                        "",
                                                                      "offset-y":
                                                                        "",
                                                                      left: "",
                                                                      "close-delay": 100
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function(
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            return [
                                                                              _c(
                                                                                "v-btn",
                                                                                _vm._g(
                                                                                  {
                                                                                    style:
                                                                                      _vm.startingDeployments.includes(
                                                                                        parseInt(
                                                                                          item.aid,
                                                                                          10
                                                                                        )
                                                                                      ) ===
                                                                                      true
                                                                                        ? "background-color:#ff5722;opacity: 0.75"
                                                                                        : _vm.runningDeployments.includes(
                                                                                            parseInt(
                                                                                              item.aid,
                                                                                              10
                                                                                            )
                                                                                          ) ===
                                                                                          true
                                                                                        ? "background-color:#11cf82; opacity: 0.75"
                                                                                        : "",
                                                                                    attrs: {
                                                                                      icon:
                                                                                        "",
                                                                                      dark:
                                                                                        _vm.startingDeployments.includes(
                                                                                          parseInt(
                                                                                            item.aid,
                                                                                            10
                                                                                          )
                                                                                        ) ===
                                                                                          true ||
                                                                                        _vm.runningDeployments.includes(
                                                                                          parseInt(
                                                                                            item.aid,
                                                                                            10
                                                                                          )
                                                                                        ) ===
                                                                                          true
                                                                                    }
                                                                                  },
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm.ApplicationInTheSameRegion(
                                                                                    item.region
                                                                                  )
                                                                                    ? _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "settings_power"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    : _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "help_outline"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card",
                                                                      [
                                                                        _c(
                                                                          "v-card-text",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-list",
                                                                              {
                                                                                attrs: {
                                                                                  dense:
                                                                                    "",
                                                                                  nav:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-list-item-group",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "primary"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-list-item",
                                                                                      {
                                                                                        attrs: {
                                                                                          disabled:
                                                                                            "",
                                                                                          "two-line":
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-list-item-content",
                                                                                          [
                                                                                            _c(
                                                                                              "v-list-item-title",
                                                                                              [
                                                                                                _c(
                                                                                                  "span",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "subtitle-2"
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        item.long_id
                                                                                                      )
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "v-list-item-content",
                                                                                              [
                                                                                                _vm.ApplicationInTheSameRegion(
                                                                                                  item.region
                                                                                                ) ===
                                                                                                false
                                                                                                  ? _c(
                                                                                                      "div",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "d-flex flex-column",
                                                                                                        staticStyle: {
                                                                                                          "max-width":
                                                                                                            "200px"
                                                                                                        }
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "span",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "text-uppercase overline mb-1"
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              " unknown "
                                                                                                            )
                                                                                                          ]
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "span",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "caption"
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              " App in a different region, click "
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "span",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "font-weight-bold"
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "Open Application"
                                                                                                                )
                                                                                                              ]
                                                                                                            ),
                                                                                                            _vm._v(
                                                                                                              " or "
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "span",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "font-weight-bold"
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "Open Instance"
                                                                                                                )
                                                                                                              ]
                                                                                                            ),
                                                                                                            _vm._v(
                                                                                                              " to change the region. "
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  : _vm.runningDeployments.includes(
                                                                                                      parseInt(
                                                                                                        item.aid,
                                                                                                        10
                                                                                                      )
                                                                                                    ) ===
                                                                                                    true
                                                                                                  ? _c(
                                                                                                      "span",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "overline text-uppercase"
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "running"
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  : _vm.startingDeployments.includes(
                                                                                                      parseInt(
                                                                                                        item.aid,
                                                                                                        10
                                                                                                      )
                                                                                                    ) ===
                                                                                                    true
                                                                                                  ? _c(
                                                                                                      "span",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "overline text-uppercase"
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "starting.."
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  : _vm.runningDeployments.includes(
                                                                                                      parseInt(
                                                                                                        item.aid,
                                                                                                        10
                                                                                                      )
                                                                                                    ) ===
                                                                                                    false
                                                                                                  ? _c(
                                                                                                      "span",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "overline text-uppercase"
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "stopped"
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  : _vm._e()
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "v-divider"
                                                                                    ),
                                                                                    _c(
                                                                                      "v-list-item",
                                                                                      {
                                                                                        attrs: {
                                                                                          to: {
                                                                                            name:
                                                                                              "app-open",
                                                                                            params: {
                                                                                              oid:
                                                                                                item.org_id,
                                                                                              sid:
                                                                                                item.space_id,
                                                                                              iid:
                                                                                                item.instance_id,
                                                                                              snid:
                                                                                                item.snid,
                                                                                              aid:
                                                                                                item.aid
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-list-item-content",
                                                                                          [
                                                                                            _c(
                                                                                              "v-list-item-title",
                                                                                              [
                                                                                                _vm.runningDeployments.includes(
                                                                                                  parseInt(
                                                                                                    item.aid,
                                                                                                    10
                                                                                                  )
                                                                                                ) ||
                                                                                                _vm.startingDeployments.includes(
                                                                                                  parseInt(
                                                                                                    item.aid,
                                                                                                    10
                                                                                                  )
                                                                                                ) ===
                                                                                                  true ||
                                                                                                _vm.ApplicationInTheSameRegion(
                                                                                                  item.region
                                                                                                ) ===
                                                                                                  false
                                                                                                  ? _c(
                                                                                                      "div",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "d-flex align-center"
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-icon",
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              small:
                                                                                                                ""
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "launch"
                                                                                                            )
                                                                                                          ]
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "span",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "primary--text subtitle-2 ml-1"
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "Open application"
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    )
                                                                                                  : _c(
                                                                                                      "div",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "d-flex align-center"
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-icon",
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              small:
                                                                                                                ""
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "play_arrow"
                                                                                                            )
                                                                                                          ]
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "span",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "primary--text subtitle-2 ml-1"
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "Start application"
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm.runningDeployments.includes(
                                                                                      parseInt(
                                                                                        item.aid,
                                                                                        10
                                                                                      )
                                                                                    ) ===
                                                                                      true ||
                                                                                    _vm.startingDeployments.includes(
                                                                                      parseInt(
                                                                                        item.aid,
                                                                                        10
                                                                                      )
                                                                                    ) ===
                                                                                      true
                                                                                      ? _c(
                                                                                          "v-list-item",
                                                                                          {
                                                                                            on: {
                                                                                              click: function(
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.stopApp(
                                                                                                  item.aid
                                                                                                )
                                                                                              }
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-list-item-content",
                                                                                              [
                                                                                                _c(
                                                                                                  "v-list-item-title",
                                                                                                  [
                                                                                                    _c(
                                                                                                      "div",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "d-flex align-center"
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-icon",
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              small:
                                                                                                                ""
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "stop"
                                                                                                            )
                                                                                                          ]
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "span",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "primary--text subtitle-2 ml-1"
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "Stop"
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              ],
                                                                                              1
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    _c(
                                                                                      "v-divider"
                                                                                    ),
                                                                                    _c(
                                                                                      "v-list-item",
                                                                                      {
                                                                                        attrs: {
                                                                                          to: {
                                                                                            name:
                                                                                              "snapshot-overview",
                                                                                            params: {
                                                                                              oid:
                                                                                                item.org_id,
                                                                                              sid:
                                                                                                item.space_id,
                                                                                              iid:
                                                                                                item.instance_id,
                                                                                              snid:
                                                                                                item.snid
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-list-item-content",
                                                                                          [
                                                                                            _c(
                                                                                              "v-list-item-title",
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "d-flex align-center"
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-icon",
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          small:
                                                                                                            ""
                                                                                                        }
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "group"
                                                                                                        )
                                                                                                      ]
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "span",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "primary--text subtitle-2 ml-1"
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "Open instance"
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-column caption"
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(" Space: "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold text-uppercase"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.space_name
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(" Instance: "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold text-uppercase"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.instance_name
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm.recentApps.length === 0
                    ? _c("div", { staticClass: "mt-4" }, [
                        _c("div", { staticClass: "caption text-center" }, [
                          _vm._v(
                            "No recent apps, add a course / project first."
                          )
                        ])
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "v-row",
                { staticClass: "mt-10 pt-12" },
                [
                  _c(
                    "v-col",
                    { attrs: { xs: "12", sm: "12", md: "4" } },
                    [
                      _c(
                        "HomeDashboardCard",
                        {
                          attrs: { icon: "school", subTitle: "Recent Courses" }
                        },
                        [
                          _vm.fetchingSpaces === false
                            ? _c(
                                "div",
                                [
                                  _vm._l(_vm.recentEducationSpaces, function(
                                    item
                                  ) {
                                    return _c(
                                      "div",
                                      { key: item.sid, staticClass: "my-6" },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _vm.fetchingSpaces ===
                                                      false
                                                        ? _c(
                                                            "v-card",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  flat: "",
                                                                  outlined: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-card-text",
                                                                {
                                                                  staticClass:
                                                                    "pa-0"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item",
                                                                    {
                                                                      attrs: {
                                                                        to: {
                                                                          name:
                                                                            "snapshot-overview",
                                                                          params: {
                                                                            oid:
                                                                              _vm
                                                                                .$route
                                                                                .params
                                                                                .oid,
                                                                            sid:
                                                                              item.sid,
                                                                            iid:
                                                                              item.iid,
                                                                            snid:
                                                                              item.snid
                                                                          }
                                                                        },
                                                                        "two-line":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-list-item-avatar",
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs: {
                                                                                "x-large":
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "school"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-content",
                                                                        [
                                                                          _c(
                                                                            "v-list-item-title",
                                                                            [
                                                                              _c(
                                                                                "h4",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-2 primary--text text-truncate"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item.space_long_id
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-list-item-subtitle",
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "caption mr-3 font-weight-bold"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item.space_description
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.space_long_id))
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _vm.educationSpaces.length > 2
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-column justify-start align-center"
                                        },
                                        [
                                          _vm.isOrgManager || _vm.isOrgFaculty
                                            ? _c(
                                                "router-link",
                                                {
                                                  staticStyle: {
                                                    "text-decoration": "none"
                                                  },
                                                  attrs: {
                                                    to: {
                                                      name: "home-add-class",
                                                      params: {
                                                        oid:
                                                          _vm.$route.params.oid
                                                      }
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-center align-center"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [_vm._v("add")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "caption primary--text"
                                                        },
                                                        [_vm._v("New Course")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.educationSpaces.length > 3
                                            ? _c(
                                                "router-link",
                                                {
                                                  staticClass: "mt-2",
                                                  staticStyle: {
                                                    "text-decoration": "none"
                                                  },
                                                  attrs: {
                                                    to: {
                                                      name:
                                                        "home-education-spaces",
                                                      params: {
                                                        oid:
                                                          _vm.$route.params.oid
                                                      }
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-center align-center"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [_vm._v("list")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "caption primary--text ml-1"
                                                        },
                                                        [_vm._v("All Courses")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                2
                              )
                            : _c(
                                "div",
                                _vm._l(
                                  [
                                    { id: "c1", opacity: 1 },
                                    { id: "c2", opacity: 0.75 },
                                    { id: "c3", opacity: 0.5 }
                                  ],
                                  function(item) {
                                    return _c(
                                      "div",
                                      { key: item.id, staticClass: "my-6" },
                                      [
                                        _c(
                                          "div",
                                          { style: { opacity: item.opacity } },
                                          [
                                            _c("v-skeleton-loader", {
                                              staticClass: "mx-auto",
                                              attrs: {
                                                type:
                                                  "list-item-avatar-two-line"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                          _vm.recentEducationSpaces.length < 3 &&
                          (_vm.isOrgManager || _vm.isOrgFaculty) &&
                          !_vm.fetchingSpaces
                            ? _c(
                                "div",
                                { staticClass: "mt-6 pa-0 py-n2" },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      staticClass: "py-2",
                                      on: {
                                        click: function($event) {
                                          return _vm.createSpaceAndApp("class")
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { "x-large": "" } },
                                            [_vm._v("add")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _c(
                                              "h4",
                                              {
                                                staticClass:
                                                  "mr-2 primary--text"
                                              },
                                              [_vm._v("New Course")]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.recentEducationSpaces.length === 0 &&
                          _vm.isOrgManager === false &&
                          _vm.isOrgFaculty === false &&
                          _vm.fetchingSpaces === false
                            ? _c("div", { staticClass: "mt-4" }, [
                                _c(
                                  "div",
                                  { staticClass: "caption text-center" },
                                  [_vm._v("No courses to display.")]
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { xs: "12", sm: "12", md: "4" } },
                    [
                      _c(
                        "HomeDashboardCard",
                        {
                          attrs: {
                            icon: "mdi-beaker",
                            subTitle: "Recent Projects"
                          }
                        },
                        [
                          _vm.fetchingSpaces === false
                            ? _c(
                                "div",
                                [
                                  _vm._l(_vm.recentResearchSpaces, function(
                                    item
                                  ) {
                                    return _c(
                                      "div",
                                      { key: item.sid, staticClass: "my-6" },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-card",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              flat: "",
                                                              outlined: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "pa-0"
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  attrs: {
                                                                    to: {
                                                                      name:
                                                                        "snapshot-overview",
                                                                      params: {
                                                                        oid:
                                                                          _vm
                                                                            .$route
                                                                            .params
                                                                            .oid,
                                                                        sid:
                                                                          item.sid,
                                                                        iid:
                                                                          item.iid,
                                                                        snid:
                                                                          item.snid
                                                                      }
                                                                    },
                                                                    "two-line":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-avatar",
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            "x-large":
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-beaker"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _c(
                                                                            "h4",
                                                                            {
                                                                              staticClass:
                                                                                "mr-2 primary--text text-truncate"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.space_long_id
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-subtitle",
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "caption mr-3 font-weight-bold"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.space_description
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.space_long_id))
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _vm.researchSpaces.length > 2
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-column align-center justify-start"
                                        },
                                        [
                                          _vm.isOrgManager || _vm.isOrgFaculty
                                            ? _c(
                                                "router-link",
                                                {
                                                  staticStyle: {
                                                    "text-decoration": "none"
                                                  },
                                                  attrs: {
                                                    to: {
                                                      name:
                                                        "home-add-research-project",
                                                      params: {
                                                        oid:
                                                          _vm.$route.params.oid
                                                      }
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-center align-center"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [_vm._v("add")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "caption primary--text"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "New Research Project"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.researchSpaces.length > 3
                                            ? _c(
                                                "router-link",
                                                {
                                                  staticClass: "mt-2",
                                                  staticStyle: {
                                                    "text-decoration": "none"
                                                  },
                                                  attrs: {
                                                    to: {
                                                      name:
                                                        "home-research-spaces",
                                                      params: {
                                                        oid:
                                                          _vm.$route.params.oid
                                                      }
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [_vm._v("list")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "caption primary--text ml-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "All Research Projects"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                2
                              )
                            : _c(
                                "div",
                                _vm._l(
                                  [
                                    { id: "r1", opacity: 1 },
                                    { id: "r2", opacity: 0.75 },
                                    { id: "r3", opacity: 0.5 }
                                  ],
                                  function(item) {
                                    return _c(
                                      "div",
                                      { key: item.id, staticClass: "my-6" },
                                      [
                                        _c(
                                          "div",
                                          { style: { opacity: item.opacity } },
                                          [
                                            _c("v-skeleton-loader", {
                                              staticClass: "mx-auto",
                                              attrs: {
                                                type:
                                                  "list-item-avatar-two-line"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                          _vm.recentResearchSpaces.length < 3 &&
                          (_vm.isOrgManager || _vm.isOrgFaculty) &&
                          !_vm.fetchingSpaces
                            ? _c(
                                "div",
                                { staticClass: "mt-6 pa-0 py-n2" },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      staticClass: "py-2",
                                      on: {
                                        click: function($event) {
                                          return _vm.createSpaceAndApp(
                                            "research"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { "x-large": "" } },
                                            [_vm._v("add")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _c(
                                              "h4",
                                              {
                                                staticClass:
                                                  "mr-2 primary--text"
                                              },
                                              [_vm._v("New Research Project")]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.recentResearchSpaces.length === 0 &&
                          _vm.isOrgManager === false &&
                          _vm.isOrgFaculty === false &&
                          _vm.fetchingSpaces === false
                            ? _c("div", { staticClass: "mt-4" }, [
                                _c(
                                  "div",
                                  { staticClass: "caption text-center" },
                                  [_vm._v("No research spaces to display.")]
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { xs: "12", sm: "12", md: "4" } },
                    [
                      _c(
                        "HomeDashboardCard",
                        {
                          attrs: {
                            icon: "mdi-database",
                            subTitle: "Recent Datasets"
                          }
                        },
                        [
                          _vm.fetchingSpaces === false
                            ? _c(
                                "div",
                                [
                                  _vm._l(_vm.recentDatasetSpaces, function(
                                    item
                                  ) {
                                    return _c(
                                      "div",
                                      { key: item.sid, staticClass: "my-6" },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-card",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              flat: "",
                                                              outlined: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "pa-0"
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  attrs: {
                                                                    to: {
                                                                      name:
                                                                        "snapshot-overview",
                                                                      params: {
                                                                        oid:
                                                                          _vm
                                                                            .$route
                                                                            .params
                                                                            .oid,
                                                                        sid:
                                                                          item.sid,
                                                                        iid:
                                                                          item.iid,
                                                                        snid:
                                                                          item.snid
                                                                      }
                                                                    },
                                                                    "two-line":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-avatar",
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            "x-large":
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-database"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _c(
                                                                            "h4",
                                                                            {
                                                                              staticClass:
                                                                                "mr-2 primary--text text-truncate"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.space_long_id
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-list-item-subtitle",
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "caption mr-3 font-weight-bold"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.space_description
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.space_long_id))
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  _vm.datasetSpaces.length > 2
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-column align-center justify-start"
                                        },
                                        [
                                          _vm.isOrgManager || _vm.isOrgFaculty
                                            ? _c(
                                                "router-link",
                                                {
                                                  staticStyle: {
                                                    "text-decoration": "none"
                                                  },
                                                  attrs: {
                                                    to: {
                                                      name: "home-add-dataset",
                                                      params: {
                                                        oid:
                                                          _vm.$route.params.oid
                                                      }
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [_vm._v("add")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "caption primary--text"
                                                        },
                                                        [_vm._v("New Dataset")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.datasetSpaces.length > 3
                                            ? _c(
                                                "router-link",
                                                {
                                                  staticClass: "mt-2",
                                                  staticStyle: {
                                                    "text-decoration": "none"
                                                  },
                                                  attrs: {
                                                    to: {
                                                      name:
                                                        "home-dataset-spaces",
                                                      params: {
                                                        oid:
                                                          _vm.$route.params.oid
                                                      }
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-center"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [_vm._v("list")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "caption primary--text ml-1"
                                                        },
                                                        [_vm._v("All Datasets")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                2
                              )
                            : _c(
                                "div",
                                _vm._l(
                                  [
                                    { id: "d1", opacity: 1 },
                                    { id: "d2", opacity: 0.75 },
                                    { id: "d3", opacity: 0.5 }
                                  ],
                                  function(item) {
                                    return _c(
                                      "div",
                                      { key: item.id, staticClass: "my-6" },
                                      [
                                        _c(
                                          "div",
                                          { style: { opacity: item.opacity } },
                                          [
                                            _c("v-skeleton-loader", {
                                              staticClass: "mx-auto",
                                              attrs: {
                                                type:
                                                  "list-item-avatar-two-line"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                          _vm.recentDatasetSpaces.length < 3 &&
                          _vm.isOrgManager &&
                          !_vm.fetchingSpaces
                            ? _c(
                                "div",
                                { staticClass: "mt-6 pa-0 py-n2" },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      staticClass: "py-2",
                                      on: {
                                        click: function($event) {
                                          return _vm.createSpaceAndApp(
                                            "dataset"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { "x-large": "" } },
                                            [_vm._v("add")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _c(
                                              "h4",
                                              {
                                                staticClass:
                                                  "mr-2 primary--text"
                                              },
                                              [_vm._v("New Dataset")]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.recentDatasetSpaces.length === 0 &&
                          !_vm.isOrgManager &&
                          _vm.fetchingSpaces === false
                            ? _c("div", { staticClass: "mt-4" }, [
                                _c(
                                  "div",
                                  { staticClass: "caption text-center" },
                                  [
                                    _vm._v(
                                      "No datasets enabled, ask your organization administrator to onboard a dataset."
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("TheOrgSelectorDialog")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }